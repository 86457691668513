import React, { useState } from "react";
import {
  Link
} from "react-router-dom";

import {Typography, Grid} from '@mui/material';

function Privacy() {
    return (
        <div className="Privacy">
            <header style={{textAlign: "center"}}>
                <h1>Privacy Statement</h1>
            </header>

            <Grid container justifyContent="center">
                <Grid xs={8}>
                
                <Typography variant="body1" gutterBottom>We at Gisa, Inc. (“Gisa,” “we,” or “us”) care deeply about your privacy. We built the SHOUT mobile application to allow you to send digitally encrypted content over an ordinary cellular network if data networks are down. We envision a world where sharing content is not limited by access to internet and data connectivity.</Typography>
                <Typography variant="body1" gutterBottom>The SHOUT application functions like this: you type a message or upload an image, document, or other file (“Content”) into the application and designate the intended recipient of the Content (“Recipient”). The application encrypts the Content based on the decryption keys of the Recipient, which were traded beforehand when you added the Recipient as a potential contact in the application. </Typography>
                <Typography variant="body1" gutterBottom>The application then turns your Content into a string of text. This process is done locally on your device, nothing is transmitted to a server or leaves your control. That string of text can then be sent to your Recipient, who will have the necessary decryption keys to see the Content in its original form again. </Typography>
                <Typography variant="body1" gutterBottom>We do not ever see your Content or have any access to it whatsoever, including in its encrypted form. We are not a messaging service; all of the communication takes place over an ordinary cellular network. Similarly, we do not have access to the encryption keys traded between you and the Recipient. The application merely encrypts the Content, putting it into a form that is transmissible as SMS on an ordinary network.</Typography>
                <Typography variant="body1" gutterBottom>Ultimately, this means that even if we wanted to, we could not provide your Content or the encryption keys to any third party. We do not know what is in your Content or who you send it to.</Typography>
                
                <Typography variant="h6">Website Viewers</Typography>
                <Typography variant="body1" gutterBottom>We collect information about how you access our website (the “Site”), including data about the networks and devices you use such as your hardware model, operating system version, IP address, mobile network, unique device identifiers, and browser type. We also collect information about your activity on the Site, including access times, pages viewed, links clicked, and the page you visited before navigating to the Site.  Similarly, we use cookies and other tracking technologies to collect information about your use of the Site. Cookies are small data files stored on your hard drive or in device memory that help us improve the Site and your experience, see which areas and features of the Site are popular, and count visits. When you return to websites that save cookies on your device—or visit websites that use the same cookies—the websites recognize the cookies and your browsing device. We also work with third-party analytics providers, including Google Analytics, who use cookies, web beacons, and other technologies to collect information about your use of the Site and other websites and applications, including pages viewed, time spent on our pages, links clicked, your mobile network information, your IP address, and your web browser information. We may use this information to analyze and track data, improve the Site, and better understand your activity and experience. Most web browsers are set to accept cookies by default. You can generally adjust your browser settings to remove or reject browser cookies; however, please note that removing or rejecting cookies could affect the availability and functionality of the Site.</Typography>
                <Typography variant="body1" gutterBottom>WE DO NOT COLLECT THIS INFORMATION ON THE MOBILE APP.</Typography>

                <Typography variant="body1" gutterBottom>If you have any questions about our privacy practices, please contact us at:</Typography>
                <Typography variant="body1"><a href="mailto:privacy@shout.tools">privacy@shout.tools</a> </Typography>

                </Grid>
            </Grid>
        </div>
    );
}

export default Privacy;
