import React, { useState } from "react";
import {
  Link, useHistory
} from "react-router-dom";

import firebase from './config/firebaseConfig';

import { getAuth,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword } from "firebase/auth";

import {Container, Button, TextField, Typography} from '@mui/material';

function Login() {
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const history = useHistory();

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    }

    const canLogin = () => {
        return email.length > 0 && password.length > 0;
    }

    const signIn = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            history.push("/media")

          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
          });
    }

    const forgotPassword = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("Password reset email sent");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    return (
        <Container className="Login">
            <header style={{textAlign: "center"}}>
                <h1>Login</h1>

                <Typography>If you have a login, please enter your email and password below.</Typography>
                <TextField id="email" label="Your email address" type="text"
                    value={email} onChange={handleChangeEmail} autoFocus fullWidth />
                <TextField id="password" label="Your password" type="password"
                    value={password} onChange={handleChangePassword} fullWidth style={{marginTop: 5}}/>

                <div style={{marginTop: 10}}>
                    <Button onClick={signIn} disabled={!canLogin()} color="primary" variant="contained">Sign in</Button>
                    <br/>
                    Or, if you forgot your password, send a reset email: <Button onClick={forgotPassword} disabled={email.length == 0}>Forgot password</Button>
                </div>
            </header>

        </Container>
    );
}

export default Login;
