import React, { useState } from "react";
import {
  Link
} from "react-router-dom";

import {Typography, Grid} from '@mui/material';

function Terms() {
    return (
        <div className="Terms">
            <header style={{textAlign: "center"}}>
                <h1>Terms of Use</h1>
            </header>

            <Grid container justifyContent="center">
                <Grid xs={8}>
                                    
                    <Typography variant="body1" gutterBottom>Welcome to SHOUT Gisa, Inc. (“Gisa,” “we,” or “us”) offers mobile software applications (each, an "App") and web services (each, a "Web Service"), allowing our users (“Users” or “you”) to encrypt photos, videos, or other content (“Content”) on your device into a string of text characters that can be shared via SMS (in a “Message”). You can send Messages over ordinary cell networks, and once they have reached their intended recipient (the “Recipient”), the Recipient will be able to decrypt the message and view your original Content. All encryption of your Content is done locally on your device, WE DO NOT STORE, PROCESS, OR OTHERWISE ACCESS YOUR CONTENT, NOR ARE WE ABLE TO.</Typography>
                    <Typography variant="body1" gutterBottom>These Terms of Use (the "Terms") is a contract you are entering into with Gisa – so you should read through all of it. We may make changes to these Terms from time to time, which we will communicate to you as appropriate. The Terms in effect at the time of any dispute shall govern that dispute.</Typography>
                    
                    
                    <Typography variant="h5">1.	GISA SERVICES<br/></Typography>

                    <Typography variant="body1" gutterBottom>
                    1.1.	Your Rights. Subject to the terms and conditions of these Terms, you are given a personal license and right to access and use the App.<br/>
                    1.2.	Content. Your use of the App is subject to the Content Rules below, which shall extend to any content created in or imported to the App. The App allows you to encrypt Content for your transmission to the Recipient. The App does NOT transmit the Content on your behalf. All transmission of the Content is at your own risk and shall rely on third party networks and other infrastructure over which Gisa has no authority or power. Gisa cannot guarantee that your Content will reach its intended Recipient without interception of any kind.<br/>
                    1.3.	Prohibited Uses. As a condition to your continued use of the App, you agree not to engage in (or encourage or permit others to engage in) any of the following (the “Prohibited Uses”):<br/>
                    •	Use the App to encrypt and transmit pornographic Content against the will of the subject of the Content, including of any children, who by their definition cannot consent;<br/>
                    •	Use the App to blackmail or extort any individual<br/>
                    1.4.	Changes to the App. Gisa reserves the right, from time to time and in its discretion, and without prior notice, to: (a) change any features and/or other aspects (such as the design, layout, or availability) of the App; (b) deactivate any features; and (c) stop supporting (or limit compatibility with) certain devices and operating systems, in which case your use of the App on such devices or operating systems may be impaired. Where required by applicable law, we will make reasonable efforts to notify you in advancein the event we decide to shut down or discontinue any App or Web Service.<br/>
                    1.5.	Reservation of Rights. For the avoidance of doubt, the App are only licensed to you, and no title in them passes to you. Any rights not expressly granted herein are hereby reserved by Gisa and its licensors, and you are granted no other right or license in the App, whether by implied license, estoppel, exhaustion, operation of law, or otherwise.<br/>
                    </Typography>

                    <Typography variant="h5">2.	YOUR ACCOUNT AND YOUR CONTENT</Typography>

                    <Typography variant="body1" gutterBottom>
                    In order to use the App, you may be required to submit certain information requested in the applicable web form or interface. You alone are responsible for providing up-to-date accurate information, maintaining the confidentiality and security of such information, as well as for all of your activities via or related to the App. Information provided will be used in accordance with our Privacy Notice (available at shout.tools/privacy), which is incorporated into these Terms by reference. You are responsible for all of your Content.<br/>
                    </Typography>

                    <Typography variant="h5">3.	INTELLECTUAL PROPERTY</Typography>
                    <Typography variant="body1" gutterBottom>
                    3.1.	App. All rights, title and interest (including without limitation all Intellectual Property Rights) in and to the App shall remain exclusively owned by Gisa and/or its licensors. If you provide us with any suggestions or other feedback about the App ("Feedback"), you agree that we may use such Feedback at our discretion, and without any restriction (e.g., for research and development purposes, for commercial purposes etc.) or obligation to you.<br/>
                    3.2.	Gisa Brands. The trademarks and branding displayed in the App (collectively, "Trademarks"), as well as their associated goodwill, are and shall remain exclusively owned by Gisa and/or its licensors. You may not use or register any mark that is confusingly similar to any Trademarks, and you must refrain from any action that may dilute, tarnish, or damage the Trademarks or their associated goodwill.<br/>
                    3.3.	Definition of Intellectual Property Rights. "Intellectual Property Rights" shall mean any rights, titles, and interests (under any jurisdiction, whether protectable or not, and whether registered or unregistered) in and to any inventions, discoveries, works of authorship, domain names, software, algorithms, designs, databases, data, know-how, technology, and/or other intellectual property, and includes without limitation patents, copyright and similar authorship rights, personal rights (such as moral rights, rights of privacy, and publicity rights), architectural, building and location (and similar geography-based) rights, mask work rights, trade secret and similar confidentiality rights, design rights, Trademarks, as well as all related applications and goodwill.<br/>
                    </Typography>

                    <Typography variant="h5">4.	THIRD-PARTY LINKS</Typography>

                    <Typography variant="body1" gutterBottom>
                    The App may include links to other websites not owned or controlled by Gisa. Gisa has no control over, assumes no liability for any loss, damage or harm arising from: (a) the privacy policies or other practices of such third parties; or (b) the content or availability of any such websites or features, and Gisa does not endorse any materials available from such websites or features.<br/>
                    Gisa encourages you to be aware when you leave the App. You should read the terms and conditions and privacy policy of each third-party website and feature before you provide them with your personal information or other Content. You are responsible for complying with the terms of all such third-party websites and features.<br/>
                    </Typography>

                    <Typography variant="h5">5.	OPEN SOURCE AND ADDITIONAL SOFTWARE COMPONENTS</Typography>
                    
                    <Typography variant="body1" gutterBottom>
                    The App contains certain open-source code or additional software components that may be subject to additional specific license terms (“Third Party Components”). In accepting these Terms, you also agree to be bound by these license terms with respect to the aforementioned Third Party Components. If there is a conflict between the licensing terms of such Third Party Components and these Terms, the licensing terms of the Third Party Components shall prevail only in connection with the related Third Party Components. These Terms does not apply to any Third Party Components accompanying or contained in the App and Gisa disclaims all liability related thereto. You acknowledge that Gisa is not the author, owner or licensor of any Third Party Components, and that Gisa makes no warranties or representations, express or implied, as to the quality, capabilities, operations, performance or suitability of Third Party Components. The relevant Third Party Components contained in the App and the licenses applicable to them can be found under the settings section in each App in Android, or under the settings in your iOS device, when you navigate to each App, as may be updated from time to time.<br/>
                    </Typography>

                
                    <Typography variant="h5">6.	DISCLAIMER OF WARRANTIES</Typography>
                    
                    <Typography variant="body1" gutterBottom>
                    USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. WE AND OUR OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS, LICENSORS, AGENTS AND AFFILIATES (COLLECTIVELY, THE “GISA PARTIES”) DISCLAIM ANY AND ALL WARRANTIES, REPRESENTATIONS, CONDITIONS AND GUARANTEES, WHETHER EXPRESSED, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, QUIET POSSESSION, TITLE, NON-INFRINGEMENT, OR THAT ARISE FROM A COURSE OF PERFORMANCE OR DEALING, OR USAGE OF TRADE.<br/>
                    WE DO NOT MAKE ANY REPRESENTATION, WARRANTY, CONDITION OR GUARANTEE THAT: (A) THE SERVICES WILL BE ERROR-FREE, OR WILL MEET YOUR REQUIREMENTS IN ANY WAY; THE SERVICES WILL BE FREE FROM MALWARE, COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; AND/OR (C) THE CONTENT WILL BE ACCURATE, COMPLETE, RELIABLE, LAWFUL, OR NON-INFRINGING. NO ORAL OR WRITTEN REPRESENTATION, ADVICE OR STATEMENT MADE BY US OR ON OUR BEHALF, WHICH IS NOT EXPRESSLY CONTAINED IN THIS AGREEMENT, SHALL BE DEEMED TO BE A REPRESENTATION, WARRANTY, GUARANTEE, OR CONDITION OF ANY KIND. ANY HOW-TO GUIDES OFFERED ARE FOR YOUR INFORMATION ONLY, AND YOU MAY NOT RELY ON THEM FOR ANY ACADEMIC, VOCATIONAL OR PROFESSIONAL CERTIFICATION.<br/>
                    </Typography>

                    <Typography variant="h5">7.	LIMITATION OF LIABILITY</Typography>

                    <Typography variant="body1" gutterBottom>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE GISA PARTIES SHALL NOT BE LIABLE UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT FOR ANY:<br/>
                    7.1.	INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY, STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGES;<br/>
                    7.2.	LOSS OF PROFIT, BUSINESS, ANTICIPATED SAVINGS, OR OPPORTUNITY;<br/>
                    7.3.	LOSS OF, OR DAMAGE TO, ANY CONTENT, REPUTATION, OR GOODWILL; AND/OR<br/>
                    7.4.	COSTS OR EXPENSES OF PROCURING SUBSTITUTE GOODS OR SERVICES.<br/>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMBINED AGGREGATE LIABILITY OF ALL GISA PARTIES UNDER, OR OTHERWISE IN CONNECTION WITH, THIS AGREEMENT SHALL NOT EXCEED AN AMOUNT EQUAL TO $100.<br/>
                    THE FOREGOING EXCLUSIONS AND LIMITATIONS SHALL APPLY: (A) EVEN IF GISA PARTIES HAVE BEEN ADVISED, OR SHOULD HAVE BEEN AWARE, OF THE POSSIBILITY OF LOSSES, DAMAGES, COSTS, OR EXPENSES; (B) EVEN IF ANY REMEDY IN THIS AGREEMENT FAILS OF ITS ESSENTIAL PURPOSE; AND<br/>
                    (B)	REGARDLESS OF THE THEORY OR BASIS OF LIABILITY, INCLUDING WITHOUT LIMITATION BREACH OF WARRANTY, NEGLIGENCE, MISREPRESENTATION, STRICT LIABILITY, OR OTHER CONTRACT, TORT OR STATUTORY LIABILITY. HOWEVER, NOTHING IN THIS AGREEMENT SHALL LIMIT LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE, OR FOR FRAUD, OR ANY OTHER LIABILITY WHICH CANNOT BE EXCLUDED BY APPLICABLE LAW.<br/>
                    </Typography>

                    <Typography variant="h5">8.	INDEMNIFICATION</Typography>
                    
                    <Typography variant="body1" gutterBottom>
                    You will indemnify and hold harmless each of Gisa, its employees, officers, directors, and affiliates (the “Gisa Parties”) from and against any losses, liabilities, damages, fines, penalties, costs, and expenses (including reasonable legal costs and attorney's fees) suffered or incurred by any Gisa Parties as a result of any breach by you under these Terms and/or any violation by you of any law and/or the rights of any third party.<br/>
                    </Typography>

                    <Typography variant="h5">9.	TERMINATION</Typography>

                    <Typography variant="body1" gutterBottom>
                    9.1.	Termination by Gisa. Gisa reserves the right, without notice and without liability, to suspend and/or terminate these Terms at any time, whether for cause (for example if you breach these Terms) or for convenience (for example if Gisa is discontinuing App).<br/>
                    9.2.	Termination by You. You may terminate these Terms at any time, via the functionality offered or by deleting the App from your device. In such cases, termination will take effect immediately.<br/>
                    9.3.	Effect of Termination; Survival. Termination of these Terms shall not affect any rights, remedies, obligations or liabilities that accrued as of the effective date of termination. Upon termination of these Terms you must uninstall the App from your device. Any provision of these Terms that should survive termination, shall survive, as shall Sections 4 (Intellectual Property) and 7 (Disclaimer of Warranties) through 12 (General Legal Terms) inclusive.<br/>
                    </Typography>

                    <Typography variant="h5">10.	GOVERNING LAW; DISPUTE RESOLUTION</Typography>

                    <Typography variant="body1" gutterBottom>
                    These Terms, as well as non-contractual claims, shall be governed by, and construed in accordance with, the laws of the State of [California], without regard to any conflicts of laws rules. The United Nations Convention on Contracts for the International Sale of Goods shall not apply to these Terms.<br/>
                    In the event of any claim, dispute or controversy in connection with these Terms (a "Dispute"), such Dispute shall be resolved exclusively by the state, federal, and local courts located in [SAN FRANCISCO COUNTY, CALIFORNIA] <br/>
                    </Typography>

                    <Typography variant="h5">11.	GENERAL LEGAL TERMS</Typography>

                    <Typography variant="body1" gutterBottom>
                    11.1.	Entire Terms. These Terms represents the entire agreement between Gisa and you with respect to the subject matter hereof, and supersedes and replaces any and all prior and contemporaneous oral and/or written agreements and understandings between you and Gisa with respect to such subject matter. You confirm that in entering into these Terms you have not relied on any statement or representation not expressly set out in these Terms, such as statements and explanations in any FAQs or other marketing material on the Gisa website. The section headings in these Terms are for convenience only and may not be used for interpretive purposes.<br/>
                    11.2.	Terms Modifications by Gisa. Gisa reserves the right, from time to time and in its discretion, to make changes to these Terms (each, "Terms Modifications"). The modified Terms will either be posted online or within the App. The effective date of the Terms Modifications will be as specified. If you do not agree to the Terms Modifications, your sole remedy, and our sole obligation and liability, is for you to terminate these Terms. Otherwise, your continued access or use of the App shall constitute your acceptance of the modified Terms.<br/>
                    11.3.	Age Representation. You represent that you are of legal age in your jurisdiction to form a binding contract, but in any event at least thirteen (13) years old. Children under the age of thirteen (13) are not permitted to use any of the App. If you are between thirteen (13) and eighteen (18) years old, you must review these Terms with your parent or guardian, have him/her accept it on your behalf, as well as approve your use of the App.<br/>
                    11.4.	Language; Electronic Contract. The language of these Terms are the English language only. You hereby irrevocably waive any law applicable to you requiring that the Terms be localized to meet your language (as well as any other localization requirements), or requiring an original (non-electronic) signature or delivery or retention of non-electronic records.<br/>
                    11.5.	App Download from App Marketplace. If you are downloading the App from a third party app- distribution platform or marketplace, such as Apple's App Store, Google's Google Play, or the Amazon Appstore for Android (each, an "App Marketplace"), please be aware that the App Marketplace may have additional rules which also govern your use of the App.<br/>
                    11.6.	Assignment. Gisa may assign these Terms (or any of its rights and/or obligations hereunder) without your consent, without notice, and without any other restriction. Gisa may, from time to time and at its discretion, subcontract performance of its obligations under these Terms. These Terms are personal to you, and you may not assign or transfer these Terms (or any of your obligations or rights hereunder) without Gisa' express prior written and signed consent. Any prohibited assignment shall be null and void. Subject to the foregoing, these Terms shall bind and benefit each party and its respective successors and assigns. At Gisa' discretion, any Gisa obligation hereunder may be performed, and any Gisa right or remedy may be exercised, by a subsidiary and/or affiliate of Gisa (each, an "Affiliate").<br/>
                    11.7.	Severability. If any provision of these Terms are held by a court to be illegal, invalid or unenforceable, then: (a) the remaining provisions of these Terms shall remain in full force and effect; and (b) you and Gisa agree that the court making such determination shall have the power to change the provision to make it legal, valid and enforceable and that most closely approximates the original intent and economic impact of such provision, and these Terms shall be enforceable as so modified in respect of such jurisdiction. In the event such court does not exercise the power granted to it as aforesaid, then such provision will be ineffective solely as to such jurisdiction, and will be substituted with a valid, legal and enforceable provision that most closely approximates the original intent and economic impact of such provision.<br/>
                    11.8.	Remedies. Except as may be expressly stated otherwise in these Terms, no right or remedy of a party under these Terms shall be exclusive of any other right or remedy under these Terms, at law or in equity.<br/>
                    11.9.	Waiver. No failure or delay on the part of any party in exercising any right or remedy under these Terms shall operate as a waiver thereof, nor shall any single exercise of any such right or remedy preclude any other or further exercise thereof or the exercise of any other right or remedy. Any waiver granted hereunder must be in writing (for waivers by you, emails will be acceptable; for waivers by Gisa, the writing must be signed by an authorized representative of Gisa), and shall be valid only in the specific instance in which given.<br/>
                    11.10.	Relationship of the Parties; Third Party Beneficiaries. No agency, partnership, joint venture or employment relationship is intended or created by these Terms, and the relationship of the parties is solely that of independent contractors. Except as provided otherwise in these Terms neither party intends that any third party will be a beneficiary of or entitled to rely on any part of these Terms.<br/>
                    11.11.	Notices. You agree that Gisa may send you notices by email, as well as provide you notices within the App. Except as stated otherwise in these Terms or required by applicable law, you agree to send all notices to Gisa, to: contact@Gisa.com.<br/>
                    11.12.	U.S. Government Rights. The App are "commercial computer software" and any App- related documentation is "commercial computer software documentation", pursuant to DFAR Section 227.7202 and FAR Section 12.212, as applicable. If you are an agency, department, employee or other entity of the United States Government, then your access to and use of the App shall be subject solely to the terms and conditions of these Terms. The encryption protocols used in the App are industry-standard and do not require registration with BIS.<br/>
                    11.13.	Export Compliance. You must comply with any applicable export control laws. You represent and warrant that: (a) you are not a resident of a country that the U.S. government has embargoed for use of the App, nor are you named on the U.S. Treasury Department’s list of Specially Designated Nationals or any other applicable trade sanctioning regulations of any jurisdiction; and (b) your country of residence and/or incorporation (as applicable) is the same as the country specified in the contact and/or billing address provided to us.<br/>
                    11.14.	Data Backup. The App are not able to and will not operate as a data storage or archiving service, and you agree not to rely on the App for the storage of any of your Content whatsoever. You are solely responsible and liable for the maintenance and backup of all your Content. <br/>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Terms;
