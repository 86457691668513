import { initializeApp } from "firebase/app";

import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAjxzluCLz5foMGwWdHgRNHlDGUDw-Dc8M",
  authDomain: "shout-sms-server.firebaseapp.com",
  projectId: "shout-sms-server",
  storageBucket: "shout-sms-server.appspot.com",
  messagingSenderId: "954838377128",
  appId: "1:954838377128:web:e92907b82cbefff688589d"
};


const app = initializeApp(firebaseConfig);

export default app;