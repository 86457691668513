import React, { useState } from "react";

import './App.css';
// import appicon from './appicon.png'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { getFunctions, httpsCallable } from "firebase/functions";
// import { getFirestore, collection, addDoc } from "firebase/firestore";

import firebase from './config/firebaseConfig';

const functions = getFunctions();

function Home() {

    return (
        <div className="App">
            <div className="FullCard Purple">
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h2">Shout makes tools for communities in low connectivity environments.</Typography>
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <img src="./images/LogoTypeWhite.png" width={300} />
                    </Grid>
                </Grid>

            </div>

            <div className="FullCard ImageCard DarkBG" style={{backgroundImage: "url(./images/africa_bg.png)"}}>

                <Grid container justifyContent="center">
                    <Grid item xs={8}>
                        <Typography variant="h4">
                            Hundreds of millions of people lack access to mobile broadband internet, connectivity that many take for granted. Many more can’t afford it.
                            <br/><br/>
                            We are working with communities to build durable and secure technologies at the intersection of voice, SMS, and data telecommunications.
                            <br/><br/>
                            Our first tool is in beta testing. Contact us to get access.
                        </Typography>
                    </Grid>
                </Grid>
            </div>

            <div className="FullCard NoPadding">

                <Grid container spacing={0}>
                    <Grid item xs={7} className="ImageCard" style={{backgroundImage: "url(./images/phone_user_dark.png)", padding: "20px"}}>
                        <Typography variant="h4" align="left" style={{color: "white"}}>
                            Shout is a simple application that allows you to send small images even when mobile data is unavailable.
                        </Typography>

                    </Grid>
                    <Grid item xs={5} style={{padding: "20px", textAlign: "left"}}>
                        <Typography variant="h5">Share with anyone</Typography>
                        <Typography paragraph>Shout allows you to send images to friends who have the app, or to a server.</Typography>

                        <Typography variant="h5">Simple and small</Typography>
                        <Typography paragraph>Install, select a photo under 500kb, adjust the quality, input a number and send.</Typography>
                    </Grid>
                </Grid>
            </div>

            <div className="FullCard Purple">

                <Grid container>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6} textAlign="left">

                        <h2>Contact us</h2>

                        {/*<Typography paragraph>We are a boot strap team with limited resources to respond to customer service issues. That’s why we are giving the app away for free :)</Typography>*/}
                        <Typography paragraph>We love feedback, partnerships, constructive criticism, postcards and brilliant new ideas.</Typography>
                        <Typography><a href="mailto:connect@shout.tools">connect@shout.tools</a></Typography>
                    </Grid>
                </Grid>
            </div>

        </div>
    );
}

export default Home;
