import React, { useState, useEffect } from "react";

import {Container, Grid} from '@mui/material';
import Spinner from './components/Spinner';
import { getAuth } from "firebase/auth";
import {printDate} from "./util";

import firebase from './config/firebaseConfig';
import { getFirestore, collection, query, orderBy, limit, onSnapshot } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function Media() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        listenForMedia();
    }, [])

    const storage = getStorage();

    const listenForMedia = () => {
        const auth = getAuth();
        console.log(auth.currentUser);
        const db = getFirestore();
        const imagesRef = query(collection(db, "images"), orderBy("ts", "desc"), limit(40));
        const unsubscribe = onSnapshot(imagesRef, (querySnapshot) => {
            const images = [];
            querySnapshot.forEach((doc) => {
              images.push(doc.data());
            });
            let n = images.length;
            console.log(`Got ${n} images`);
            setImages(images);
        });
    }

    return (
        <Container className="Media">
            <header style={{textAlign: "center"}}>
                <h1>Media Received</h1>

                <MediaList images={images} storage={storage} />
            </header>

        </Container>
    );
}

function ImageItem(props) {
    const [displayUrl, setDisplayUrl] = useState(null)

    useEffect(() => {
        if (props.media_url != null) {
            getImageDisplayUrl(props.media_url)
        }
    }, [props.media_url])

    const getImageDisplayUrl = (gs_url) => {
        const storage = props.storage;
        getDownloadURL(ref(storage, gs_url))
          .then((url) => {
            setDisplayUrl(url)
          })
          .catch((error) => {
            // Handle any errors
          });
    }

    const printStatus = (status) => {
        return ["First Part Received", "Last Part Received", "Processed"][status];
    }

    const showSpinner = () => {
        return props.status < 2;
    }

    return (
        <Grid item xs={3} className="MediaItem" key={props.id}>
            { displayUrl != null && !showSpinner() ? <img src={displayUrl} alt={props.id} className="image" /> : null }
            { showSpinner() ? <Spinner /> : null }
            <div className="metadata">
                <span>ID: { props.id }</span>
                <span>Sender: { props.sender }</span>
                <span>Date: { printDate(props.ts) }</span>
                <span>Status: { printStatus(props.status) }</span>
            </div>
        </Grid>
    )
}

function MediaList(props) {

    return (
        <Grid container className="MediaList">
            { props.images.map((im) => {
                return <ImageItem key={im.id} storage={props.storage} {...im} />;
            }) }
        </Grid>
    )
}

export default Media;
